import {useNavigate} from "react-router-dom";

export const OrdersFilterLink = ({filterViewNumber, title, count, parentClass, countClass}) => {
    const navigate2 = useNavigate();
    return (
        <div className={`relative flex items-center cursor-pointer ${parentClass}`}>
            <div className="mr-2 text-sm underline" onClick={() => navigate2('/dashboard/5?filterView=' + filterViewNumber)}>{title}</div>
            {count > 0 &&
                <div className={`absolute top-[-10px] right-[-15px] text-xs rounded-full p-2 flex items-center justify-center w-5 h-5 ${countClass}`}>{count}</div>
            }
        </div>
    )
}